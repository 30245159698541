@import '../../assets/styles/variables';

footer {
  height: 70px;
  background-color: $main-gold;
  .footer-content {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 0 2rem;
    @media (min-width: 992px) {
      padding: 0 10%;
    }
  }
  .logo {
    height: 50%;
    @media (max-width: 991.98px) {
      display: none;
    }
  }
  span {
    font-size: 0.8rem;
    color: #ffffff;
  }
  .contact {
    height: 0.8rem;
  }
}