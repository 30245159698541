@import '_base';

body {
	margin: 0;
	font-family: $main-font;
}

.main-banner {
	height: 140px;
	background-color: #ffffff;
	display: flex;
	justify-content: center;
	align-items: center;
	img {
		height: 40%;
	}
}

.main-header-img {
	height: calc(83vh - 210px);
	img {
		height: 100%;
		width: 100%;
		object-fit: cover;
		object-position: 0 55%;
	}
}

.main-content {
	padding: 4rem 2rem;
	@media (min-width: 992px) {
		padding: 4rem 10% 8rem 10%;
	}
	.main-box-container {
		cursor: pointer;
		&:first-of-type {
			padding-right: 0.5rem;
		}
		&:last-of-type {
			padding-left: 0.5rem;
		}
		&:nth-child(2),
		&:nth-child(3) {
			padding: 0 0.5rem;
		}
		img {
			width: 100%;
			height: 100%;
		}
	}
	.main-image {
		display: flex;
		width: 100%;
		img {
			width: 100%;
			height: 220px;
		}
		@media (min-width: 1200px) {
			flex: 1;
		}
	}
	h1 {
		color: $main-gray;
		font-weight: bold;
	}
	h4 {
		color: $main-light-gray;
		font-weight: bold;
		&.darker {
			color: $main-gray;
		}
	}
	p {
		color: $main-light-gray;
	}
	.main-list {
		margin: 1rem 0;
		font-size: 1.25rem;
		color: $main-light-gray;
		img {
			width: 40px;
			height: 40px;
			margin-right: 1rem;
		}
		&:last-of-type {
			@media (min-width: 992px) {
				margin-bottom: 0.5rem;
			}
		}
	}
	.main-icon {
		display: flex;
		justify-content: center;
		img {
			width: 140px;
			height: 140px;
		}
		@media (max-width: 991.98px) {
			margin-bottom: 3rem;
		}
	}
	.main-column-info {
		font-size: 1.5rem;
		color: $main-gray;
		@media (max-width: 991.98px) {
			margin-bottom: 3rem;
		}
	}
}

.main-container-border {
	@media (min-width: 992px) {
		border-right: 4px solid $main-gray;
	}
}

.gold-border {
	border: 2px solid $main-gold;
	border-left-width: 0;
	border-right-width: 0;
	display: flex;
	align-items: flex-end;
}

img.map {
	height: calc(100% - 1rem);
	@media (max-width: 991.98px) {
		padding-bottom: 3rem;
		height: 350px;
	}
}
img.value {
	width: 100%;
	padding: 3rem 0;
}

.financial-section {
	padding-bottom: 10rem;
}

.real-estate-modal.react-responsive-modal-modal {
	width: 700px;
	@media (max-width: 991.98px) {
		max-width: none;
		width: 100%;
	}
	.slick-list,
	.slick-track,
	.slick-slide {
		height: 100%;
	}
}
.react-responsive-modal-closeButton.modal-close-circle {
	top: 1.5rem;
	right: 1.5rem;
	svg {
		path {
			fill: #ffffff;
		}
	}
}
