@import '../../assets/styles/variables';

.landing-slider,
.real-estate-photos-slider {
	width: 100%;
	.slick-slide {
		position: relative;
		height: calc(83vh - 210px);
		background-color: #ffffff;
		div {
			height: 100%;
		}
		img {
			height: 100%;
			object-fit: cover;
		}
	}
	.slick-arrow {
		z-index: 10;
		height: 100%;
		&:focus {
			outline: none;
		}
		&.next,
		&.prev {
			filter: brightness(5);
			cursor: pointer;
			position: absolute;
			right: 1.5rem;
			height: 70px;
			width: 35px;
			top: 50%;
			transform: translate(0, -50%);
			display: flex;
			align-items: center;
			img {
				height: 25px;
				width: 30px;
			}
		}
		&.next {
			justify-content: flex-end;
			img {
				transform: rotate(180deg);
			}
		}
		&.prev {
			left: 1.5rem;
		}
	}
}

.real-estate-photos-slider {
	.slick-slide {
		img {
			width: 100%;
		}
	}
}
